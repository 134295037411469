import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import PublicRoutes from './routes/public-routes'
import Loading from './components/layout/loading'

import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/main.css'

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Suspense fallback={<Loading/>}>
                <Switch>
                    <PublicRoutes/>
                </Switch>
            </Suspense>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
)