import FadeIn from 'react-fade-in'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
    return (
        <FadeIn>
            <div className="loading">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </FadeIn>
    )
}

export default Loading