import React from 'react'

const HomePage = React.lazy(() => import('../pages/home'))
const StatPage = React.lazy(() => import('../pages/stat/StatPage'))
const NSWSystemPage = React.lazy(() => import('../pages/nswSystem/NSWSystemPage'))
const ReportPage = React.lazy(() => import('../pages/report/ReportPage'))

const routes = [
    { path: '/', exact: true, name: 'HomePage', component: HomePage }
]

export default routes

export const routesMain = [
    { path: '/statistic', exact: true, name: 'StatPage', component: StatPage, menu_name: "รายงานผลสถิติ", title: "สถิติผู้เข้าใช้/เข้าชม" },
    { path: "/nsw_system", exact: true, name: 'NSWSystemPage', component: NSWSystemPage, menu_name: "ระบบ NSW", title: "สถิตินำเข้าส่งออก โบราณวัตถุ ศิลปวัตถุ (NSW)"  },
    { path: '/report', exact: true, name: 'ReportPage', component: ReportPage, menu_name: "ระบบออกเอกสารรายงาน", title: "ออกเอกสารรายงาน"  }
]