import React from 'react'
import NavigationMain from './NavigationMain'
import HeaderMain from './HeaderMain'
import ContentMain from './ContentMain'
import FooterMain from './FooterMain'


const MainLayout = () => {
    return (
        <div style={{backgroundColor:"white"}}>
            <NavigationMain/>
            <HeaderMain/>
            <div className="content-wrapper">
                <ContentMain/>
            </div>
            <FooterMain/>
        </div>
    )
}

export default MainLayout