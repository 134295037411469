import React from "react";
import "./css/footerMain.css"
import { Col, Row } from "react-bootstrap";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const FooterMain = ()=>{
    return(<footer className="footer-main">
        <Row>
            <Col md={2}>
                <h4>กรมศิลปากร</h4>
                <h4>เลขที่ ๘๑/๑ อาคารกรมศิลปากรถนนศรีอยุธยา แขวงวชิระพยาบาล ดุสิต กรุงเทพฯ ๑๐๓๐๐</h4>
            </Col>
            <Col md={2}>
                <h4>ติดต่อเรา</h4>
                <h4>เบอร์ / Tel: <span className="value-field">02-164-2524</span></h4>
                <h4>Email: <span className="value-field">icts@finearts.go.th</span></h4>
            </Col>
            <Col>
            </Col>
            <Col md={2} >
                <h4 className="social-label">เครือข่ายังคมออนไลน์ของกรมศิลปากร</h4>
                <div className="social">
                <FaYoutube className="social-icon" onClick={()=> window.open("https://youtube.com/@finearts.d?si=Ktzyclnjv5j0fRaY", '_blank')}/>
                <FaFacebook className="social-icon" onClick={()=> window.open("https://www.facebook.com/FineArtsDept", '_blank')} />
                </div>
            </Col>
        </Row>
    </footer>)
}

export default FooterMain