import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className="error-404">
            <div className="logo">
                <img src="/images/logo.png" alt="logo-finearts" className="logo-img"/>
                <div className="logo-title">
                    <h1>กรมศิลปากร</h1>
                    <h1>Fine Arts Department</h1>
                </div>
            </div>
            <h1 className="message">ขออภัย ไม่พบหน้าที่คุณต้องการ กรุณาลองใหม่อีกครั้ง</h1>
            <Link to="/" className="btn btn-brown back-home">กลับหน้าหลัก</Link>
        </div>
    )
}

export default NotFound