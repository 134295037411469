import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Container } from 'react-bootstrap'
import FadeIn from 'react-fade-in'

import { routesMain } from '../../routes/index'
import Loading from '../layout/loading'

const ContentMain = () => {
    return (
        <main>
            <Container>
                <Suspense fallback={<Loading/>}>
                    <Switch>
                        {routesMain.map((route, i) => {
                            return route.component && (
                                <Route
                                    key={i}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                        <FadeIn>
                                            <route.component {...props}/>
                                        </FadeIn>
                                    )}
                                />
                            )
                        })}
                    </Switch>
                </Suspense>
            </Container>
        </main>
    )
}

export default React.memo(ContentMain)