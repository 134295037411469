import React from 'react'
import { Route } from 'react-router-dom'

import { checkPath } from '../actions/utilities/utils'

import NotFound from '../pages/error/404'
import routes, { routesMain } from '.'
import MainLayout from '../components/main_layout'
const Layout = React.lazy(() => import('../components/layout'))

const PublicRoutes = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            checkPath(rest, routes) ?
            <Layout {...props}/>
            :checkPath(rest, routesMain) ?<MainLayout {...props}/>
            : <Route exact path="/*" component={NotFound}/>
        )}/>
    )
}

export default PublicRoutes