import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { routesMain } from '../../routes'
import "./css/navigationMain.css"

const NavigationMain = () => {
    return (
        // fixed="top"
        <Navbar className="navbar-finearts" style={{"backgroundColor": "white", "color": "black"}}  collapseOnSelect  expand="lg">
            <Container>
                <Navbar.Brand href={routesMain[0].path} className="logo">
                    <img src="/images/logo.png" alt="logo-finearts" className="logo-img"/>
                    <div className="logo-title">
                        <h5>กรมศิลปากร</h5>
                        <h5>Fine Arts Department</h5>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav-main"/>
                <Navbar.Collapse id="responsive-navbar-nav-main">
                    <Nav>
                        {
                            routesMain.map(route=>{
                                return(<Nav.Link href={route.path} >{route.menu_name}</Nav.Link>)
                            })
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default React.memo(NavigationMain)