export const checkPath = (rest, routes) => {
    for ( const item of routes ) {
        if ( item.path === rest.location.pathname ) return true
    }
    return false
}

export const subStringMaxChar = (str, max) => {
    if ( str ) {
        if ( str.length > max ) {
            return `${str.substr(0, max)}...`
        } else {
            return str
        }
    }
}

export const checkImageFromUrl = (url) => {
    if ( url && url !== '-' ) {
         return url
    } else {
        return '/images/no-image-center.png'
    }
}

export const isStatusOk = (response)=>{
    return response.status === 200
}

export const isStatusOkAndHasData = (response) =>{
    return isStatusOk(response) && isNotEmpty(response.data, 'object')
}

export const isStatusOkAndHasDataArray = (response) =>{
    return isStatusOk(response) && isNotEmpty(response.data, 'array')
}

export const isNotNull = (value) => {
    return value
}

const isTypeExpected = (type, expect) => {
    if (isNotNull(type)) {
        return type === expect
    }
    return true
}
export const isNotEmpty = (value, type) => {
    let result = isNotNull(value)
    if (result) {
        if (Array.isArray(value)) {
            if (isTypeExpected(type, 'array')) {
                return value.length > 0
            } else {
                return false
            }
        } else if (typeof (value) === 'object') {
            if (isTypeExpected(type, 'object')) {
                return Object.keys(value).length > 0
            } else {
                return false
            }
        } else if (typeof (value) === 'string') {
            if (isTypeExpected(type, 'string')) {
                return value !== ""
            } else {
                return false
            }
        }
    }
    return result
}
