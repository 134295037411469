import React, { useEffect, useState } from 'react'
import { routesMain } from '../../routes'
import "./css/headerMain.css"

const style = {
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/header.png')`
}

const HeaderMain = () => {
    const [title, setTitle] = useState("")
    useEffect(()=>{
        if (window.location.pathname) {
            let route = routesMain.filter(r => r.path === window.location.pathname)
            if (route && route.length >0) {
                setTitle(route[0].title)
            } else {
                setTitle("")
            }
        } else {
            setTitle("")
        }

    },[window.location.pathname])
    return (
        <div className="header" style={style}>
            <div className='card-overlay'>
                <div className="header-main">
                <h3 className='header-main-text'>{title}</h3>
                </div>

            </div>
        </div>
    )
}

export default React.memo(HeaderMain)